import React, { useState, useEffect, useRef } from "react";
import Image from "next/image";

import Aos from 'aos';
import 'aos/dist/aos.css';

export default function ImageWrapper(props) {

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      Aos.init();
    }
  }, [isLoaded]);

  return (
    <Image 
      alt={props.alt ?? ''}
      {...props} 
      blurDataURL={props.blur ?? ''}
      placeholder={props.blur != '' ? 'blur' : 'empty'}
      data-aos="fadeIn" 
      onLoad={() => setIsLoaded(true)} 
    />
  );
}